import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import NavDesk from "./NavDesk";
import NavMobile from "./NavMobile";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Nav: React.FC = () => {
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar position='static' style={{ background: "white", color: "black" }}>
      {downSM ? <NavMobile /> : <NavDesk />}
    </AppBar>
  );
};

export default Nav;
