import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const useThemeMediaQuery = () => {
  const theme = useTheme();

  const QueryTypeSize = (type, size) => {
    let queryBreak = useMediaQuery(theme.breakpoints[type](size));

    return queryBreak;
  };

  return { QueryTypeSize };
};
