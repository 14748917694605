import { Box, Container, Typography } from "@material-ui/core";
import React from "react";

const Footer: React.FC = () => {
  return (
    <Box bgcolor='black' color='white' py={3}>
      <Container>
        <Box>
          <Box>
            <Typography variant='h4'>CALI NAILS</Typography>
          </Box>

          <Box>
            <Typography variant='h6'>1517 SUN CITY CENTER PLAZA #B,</Typography>
          </Box>
          <Box>
            <Typography variant='h6'>33573 SUN CITY CENTER, FL</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
