import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import AccordionUnitDepBeautyBar from "./depbeautybar/AccordionUnitDepBeautyBar";
import hairData from "../../data/depbeautybar/hair.json";
// import nailData from "../../data/depbeautybar/nail.json";
import AccordionUnitCaliNails from "./calinails/AccordionUnitCaliNails";
import acrylicData from "../../data/calinails/acrylicData.json";
import manipedData from "../../data/calinails/manipedData.json";
import waxingData from "../../data/calinails/waxingData.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: "1.7rem",
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const AccordionBase = () => {
  const classes = useStyles();

  return (
    <Box my={5}>
      <AccordionUnitCaliNails serviceData={acrylicData} />
      <AccordionUnitCaliNails serviceData={manipedData} />
      <AccordionUnitCaliNails serviceData={waxingData} />
      {/* <AccordionUnitDepBeautyBar serviceData={hairData} /> */}
    </Box>
  );
};

export default AccordionBase;
