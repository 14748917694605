import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React from "react";
import Iframe from "react-iframe";

const Contact: React.FC = () => {
  return (
    <Box bgcolor='whitesmoke' pt={15} pb={10}>
      <Container>
        <Box textAlign='center'>
          <Typography variant='h2'>CONTACT</Typography>
          <Box my={3}>
            <Typography variant='h5'>Appointments & Walk-In-Welcome</Typography>
          </Box>

          <Box my={5}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box my={5}>
                  <Box>
                    <Typography variant='h5'>HOURS</Typography>
                  </Box>
                  <Box my={2}>
                    <Typography variant='h4'>Monday - Saturday</Typography>
                    <Typography variant='h4'>9am - 6pm</Typography>
                    <Typography variant='body1'>
                      closed most major holidays
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box my={5}>
                  <Typography variant='h5'>PHONE</Typography>
                  <Box my={1}>
                    <Typography variant='h4'>(813) 634-6500</Typography>
                  </Box>

                  <Box m={3}>
                    <Button
                      variant='contained'
                      href='tel:1-503-644-2050'
                      // size='small'
                    >
                      Call Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box my={5}>
                  <Typography variant='h5'>ADDRESS</Typography>
                  <Box my={2}>
                    <Link
                      href='https://goo.gl/maps/df5aVgqC6U26N9HA7'
                      rel='noreferrer'
                      // onClick={preventDefault}
                      target='_blank'
                    >
                      <Box>
                        <Typography variant='h5'>
                          1517 Sun City Center Plaza # B
                        </Typography>
                        <Typography variant='h5'>
                          Sun City Center, FL 33573
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                  <Box m={3}>
                    <Link
                      href='https://goo.gl/maps/df5aVgqC6U26N9HA7'
                      rel='noreferrer'
                      // onClick={preventDefault}
                      target='_blank'
                    >
                      <Button variant='contained'>GET DIRECTION</Button>
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  my={5}
                  textAlign='center'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                  mx='auto'
                >
                  <Box my={3}>
                    <Typography variant='h5'>GOOGLE MAP</Typography>
                  </Box>

                  <Box border={1} width='100%'>
                    <Iframe
                      url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.1165310546658!2d-82.3556941928723!3d27.713688101198287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2d7d624865891%3A0x6d084152a1720277!2sCALI%20NAILS!5e0!3m2!1sen!2sus!4v1619203857207!5m2!1sen!2sus'
                      width='100%'
                      height='450'
                      frameBorder={1}
                      // style='border:0;'
                      // allowfullscreen=''
                      // loading='lazy'
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box textAlign='center' mb={5}>
          <Button
            variant='contained'
            size='large'
            style={{ backgroundColor: "green" }}
          >
            <a
              href='tel:1-813-634-6500'
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography variant='h5'>CALL (813) 634-6500</Typography>
            </a>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
