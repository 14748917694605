import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, List, ListItem } from "@material-ui/core";

type DataType = {
  category: string;
  units: UnitType[];
};

type UnitType = {
  title: string;
  list: string[];
};

interface ServiceDataProps {
  serviceData: DataType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: "1.7rem",
      fontWeight: theme.typography.fontWeightBold,
      color: "#3f51b5",
    },
  })
);

const AccordionUnitCaliNails: React.FC<ServiceDataProps> = ({
  serviceData,
}) => {
  const classes = useStyles();

  return (
    <Accordion style={{ backgroundColor: "gainesboro" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel123a-header'
        style={{ backgroundColor: "whitesmoke" }}
      >
        <Typography className={classes.heading}>
          {serviceData.category}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify='flex-start' alignItems='flex-start'>
          {serviceData.units.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                <List dense style={{ textAlign: "center" }}>
                  <ListItem>
                    <Typography variant='h6'>{item.title}</Typography>
                  </ListItem>
                  {item.list.map((item2) => {
                    return <ListItem>{item2}</ListItem>;
                  })}
                </List>
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionUnitCaliNails;
