import { Box, Container, Typography } from "@material-ui/core";
import AccordionBase from "./AccordionBase";

const Service = () => {
  return (
    <Box textAlign='center'>
      <Box my={5}>
        <Typography variant='h2'>SERVICES</Typography>
      </Box>
      <Box m='auto' style={{ maxWidth: 1000 }}>
        <AccordionBase />
      </Box>
    </Box>
  );
};

export default Service;
