import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import aboutSalon from "../../image/aboutSalon.jpg";
import moon from "../../image/moon.jpg";
import Text from "./Text";

const About: React.FC = () => {
  return (
    <Box>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
          <Box
            m='auto'
            style={{
              overflow: "hidden",
              backgroundRepeat: "no-repeat",

              width: "auto",
              height: 300,
            }}
          >
            <img
              src={aboutSalon}
              alt='flower'
              style={{
                objectPosition: "center",
                height: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Text />
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
