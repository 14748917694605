import { Box, Typography } from "@material-ui/core";
import React from "react";
import ImageGallery from "react-image-gallery";

import p1 from "../../image/gallery/p2.jpeg";
import p2 from "../../image/gallery/p1.jpeg";
import p3 from "../../image/gallery/p3.jpeg";
import p4 from "../../image/gallery/p4.jpeg";
import p5 from "../../image/gallery/p5.jpeg";

const Gallery: React.FC = () => {
  const imageData = [
    {
      original: `${p1}`,
    },
    {
      original: `${p2}`,
    },
    {
      original: `${p3}`,
    },
    {
      original: `${p4}`,
    },
    {
      original: `${p5}`,
    },
  ];

  return (
    <Box textAlign='center'>
      <Box mb={4}>
        <Typography variant='h2'>GALLERY</Typography>
      </Box>
      <Box
        style={{ maxWidth: 900, maxHeight: 500, overflow: "hidden" }}
        textAlign='center'
        display='flex'
        justifyContent='center'
        alignItems='center'
        mx='auto'
      >
        <ImageGallery
          items={imageData}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          showBullets={true}
          showIndex={true}
          lazyLoad={true}
        />
      </Box>
    </Box>
  );
};

export default Gallery;
