import React from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

// this is a hook that wraps a typograph variant element
// it will auto resize font to fit XS screen
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export const useResponsiveFontSizes = () => {
  const ResponsiveFontSizeWrapper = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  };
  return { ResponsiveFontSizeWrapper };
};

export default useResponsiveFontSizes;
