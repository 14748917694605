import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
//@ts-ignore
import AnchorLink from "react-anchor-link-smooth-scroll";

const NavMobile: React.FC = () => {
  const [drawerState, setDrawerState] = React.useState<boolean>(false);

  const toggleDrawer = (open: boolean) => {
    console.log(drawerState);
    setDrawerState(open);
  };

  const list = () => (
    <div role='presentation' onClick={() => toggleDrawer(false)}>
      <List>
        <AnchorLink
          href='#hero'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <ListItem button style={{ textAlign: "center" }}>
            <ListItemText
              primary={<Typography variant='h3'>CALI NAILS</Typography>}
            />
          </ListItem>
        </AnchorLink>

        <AnchorLink
          href='#about'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <ListItem button style={{ textAlign: "center" }}>
            <ListItemText
              primary={<Typography variant='h3'>ABOUT</Typography>}
            />
          </ListItem>
        </AnchorLink>

        <AnchorLink
          href='#gallery'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <ListItem button style={{ textAlign: "center" }}>
            <ListItemText
              primary={<Typography variant='h3'>GALLERY</Typography>}
            />
          </ListItem>
        </AnchorLink>

        <AnchorLink
          href='#service'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <ListItem button style={{ textAlign: "center" }}>
            <ListItemText
              primary={<Typography variant='h3'>SERVICES</Typography>}
            />
          </ListItem>
        </AnchorLink>

        <AnchorLink
          href='#contact'
          offset='50'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <ListItem button style={{ textAlign: "center" }}>
            <ListItemText
              primary={<Typography variant='h3'>CONTACT</Typography>}
            />
          </ListItem>
        </AnchorLink>

        <Box textAlign='center' pt={5}>
          <a
            href='tel:1-813-634-6500'
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              size='large'
            >
              <Typography variant='h4'>CALL NOW</Typography>
            </Button>
          </a>
        </Box>
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        anchor={"left"}
        open={drawerState}
        onClose={() => toggleDrawer(false)}
      >
        {list()}
      </Drawer>
      <AppBar position='fixed' style={{ background: "white", color: "black" }}>
        <Toolbar>
          <Box>
            <AnchorLink
              href='#hero'
              offset='75'
              style={{ textDecoration: "none", color: "#3f51b5" }}
            >
              <Button size='large'>
                <Typography variant='h4' style={{ color: "red" }}>
                  CALI NAILS
                </Typography>
              </Button>
            </AnchorLink>
          </Box>
          <Button size='large' style={{ backgroundColor: "inherit" }}>
            <a
              href='tel:1-813-634-6500'
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.7)" }}
            >
              <Typography variant='subtitle1'>(813) 634-6500</Typography>
            </a>
          </Button>
          <Box style={{ flex: 1 }} />
          <IconButton
            edge='start'
            aria-label='menu'
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavMobile;
