import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

const Text = () => {
  return (
    <Box mt={2}>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Box my={3}>
            <Typography variant='h2'>ABOUT</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant='h5'>FRIENDLY - PROFESSIONAL - SAFE</Typography>
          <Box p={3} textAlign='center'>
            <Typography variant='body1'>
              Kim & James, mother and son, opened Cali Nails in 2011 right at
              heart of Sun City Center, FL. Their goals were to create a
              friendly nail salon that provided excellent nail care. With over
              25 years of professional nail care services, they are ready for
              any nail care needs. Along with the other nail technicians, they
              hope everyone will stop by to enjoy a relaxing day at Cali Nails.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Text;
