import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import React from "react";
//@ts-ignore
import AnchorLink from "react-anchor-link-smooth-scroll";

const NavDesk: React.FC = () => {
  return (
    <AppBar position='fixed' style={{ background: "white", color: "black" }}>
      <Toolbar>
        {/* <IconButton
          edge='start'
          className={classes.menuButton}
          
          aria-label='menu'
        >
          <MenuIcon />
        </IconButton> */}
        <Box mx={3}>
          <AnchorLink
            href='#hero'
            offset='75'
            style={{ textDecoration: "none", color: "#3f51b5" }}
          >
            <Button size='large'>
              <Typography variant='h4' style={{ color: "red" }}>
                CALI NAILS
              </Typography>
            </Button>
          </AnchorLink>
        </Box>

        <AnchorLink
          href='#about'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <Button size='large'>ABOUT</Button>
        </AnchorLink>
        <AnchorLink
          href='#gallery'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <Button size='large'>GALLERY</Button>
        </AnchorLink>
        <AnchorLink
          href='#service'
          offset='75'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <Button size='large'>SERVICES</Button>
        </AnchorLink>
        <AnchorLink
          href='#contact'
          offset='30'
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          <Button size='large'>CONTACT</Button>
        </AnchorLink>

        <Button
          // variant='contained'
          size='large'
          // style={{ backgroundColor: "green" }}
        >
          <a
            href='tel:1-813-634-6500'
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.7)" }}
          >
            <Typography variant='subtitle1'> (813) 634-6500</Typography>
          </a>
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavDesk;
