import { Box, Typography } from "@material-ui/core";
import React from "react";
import Nav from "./Nav";

const Header: React.FC = () => {
  return (
    <Box>
      <Nav />
    </Box>
  );
};

export default Header;
